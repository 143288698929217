<template>
  <v-dialog
    v-model="showDialog"
    :max-width="800"
    data-testid="archive-audit-dialog"
  >
    <template #activator="{ on }">
      <v-btn
        fab
        x-small
        color="#e0dcd0"
        class="lighten-5 elevation-1"
        v-on="on"
      >
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </template>

    <DialogCard @closeDialog="showDialog = false">
      <template #title> Audit trail for data archiving </template>

      <div class="pb-4">
        <span class="filename mr-4">{{ archive.zipFileName }} </span>
        {{ zipFileSize }} <br />
        <span v-if="archive.patientsIncluded" class="font-weight-bold"
          >{{ archive.patientsIncluded }} patients</span
        >
      </div>

      <v-data-table
        :disable-sort="true"
        :fixed-header="true"
        :headers="getTableHeaders()"
        :items="tableData"
        item-key="rowVersionId"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions,
        }"
        dense
      >
        <template #item="{ item }">
          <tr class="log-row">
            <td class="log-type">
              {{ $t('generation-status-log-type.' + item.logType) }}
            </td>
            <td>
              <DateFormat :value="localDate(item)" class="mr-5" />
            </td>
            <td>
              <DateFormat format="HH:mm" :value="localDate(item)" />
            </td>
            <td><VersionBy :versionBy="item.requestedBy" /></td>
          </tr>
        </template>
      </v-data-table>
    </DialogCard>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import itemsPerPageOptions from '@/constants/itemsPerPageOptionsPagination'
import DialogCard from '@/components/DialogCard.vue'
import DateFormat from '@/components/DateFormat.vue'
import VersionBy from '@/components/VersionBy.vue'

export default Vue.extend({
  name: 'ArchiveAuditDialog',
  components: {
    DialogCard,
    DateFormat,
    VersionBy,
  },
  props: {
    archive: { type: Object, required: true },
  },
  data() {
    return {
      itemsPerPageOptions,
      showDialog: false,
    }
  },
  computed: {
    tableData() {
      return this.archive?.archiveLogs || []
    },
    zipFileSize() {
      if (this.archive.zipFileSize) {
        return this.archive.zipFileSize
      }

      return '(File size pending)'
    },
  },
  methods: {
    getTableHeaders() {
      return [
        { text: 'Archive status', value: 'generationStatus', sortable: false },
        { text: 'Date', value: 'date', sortable: false },
        {
          text: `Time (24h)`,
          value: 'time',
          sortable: false,
        },
        { text: 'By', value: 'by' },
      ]
    },
    localDate(item) {
      return new Date(item.requestedAt.utcTime)
    },
  },
})
</script>

<style lang="scss" scoped>
.log-row {
  height: 50px;
}
.filename {
  font-weight: bold;
  color: $nn-sea-blue;
}
.log-type {
  font-weight: bold;
  color: $nn-D_T40;
}
</style>
