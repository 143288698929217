
import Vue, { PropType } from 'vue'
import { rolesText } from '@/constants/userRole'

interface VersionByDto {
  email: string
  name: string
  role: string
}

export default Vue.extend({
  name: 'VersionBy',
  props: {
    versionBy: { type: Object as PropType<VersionByDto>, required: true },
  },
  computed: {
    rolesText() {
      return rolesText(this.versionBy.role)
    },
  },
})
