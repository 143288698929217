<template>
  <span>{{ formattedDate }}</span>
</template>
<script>
import format from 'date-fns/format'
import isDate from 'date-fns/isDate'
import isValid from 'date-fns/isValid'
import { enGB } from 'date-fns/locale'

import parseDateTimeWithoutTimeZone from '@/utils/date/parseDateTimeWithoutTimeZone'

const formatDate = (date, formatString) => {
  return format(date, formatString, { locale: enGB })
}
export default {
  name: 'DateFormat',
  props: {
    value: { type: [Object, String, Date], default: null },
    notDateText: { type: String, required: false, default: null },
    format: { type: String, required: false, default: 'dd MMM yyyy' },
  },
  computed: {
    formattedDate() {
      if (isDate(this.value) && isValid(this.value))
        return formatDate(this.value, this.format)

      if (typeof this.value === 'string') {
        const date = parseDateTimeWithoutTimeZone(this.value)
        if (isValid(date)) {
          return formatDate(date, this.format)
        }
      }

      return this.notDateText || '—'
    },
  },
}
</script>
