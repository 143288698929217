
import Vue from 'vue'

export default Vue.extend({
  name: 'WarningBox',
  props: {
    expand: { type: Boolean, required: false, default: false },
    dense: { type: Boolean, required: false, default: false },
    isWarningDangerBox: { type: Boolean, required: false, default: false },
  },
})
