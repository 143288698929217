<template>
  <v-breadcrumbs
    v-if="hasBreadcrumbItems"
    :items="breadcrumbList"
    divider="/"
    class="px-0 pt-0"
  >
    <v-breadcrumbs-item
      slot="item"
      slot-scope="{ item }"
      :to="{ name: item.link }"
      :disabled="false"
      :exact="true"
    >
      {{ getDisplayText(item.name) }}
    </v-breadcrumbs-item>
  </v-breadcrumbs>
</template>

<script>
import { trialSettingsMapGetters } from '@/store/modules/trialSettingsModule'
import userGettersMixin from '@/components/mixins/store/userGettersMixin'

export default {
  name: 'Breadcrumbs',
  mixins: [userGettersMixin],
  data() {
    return {
      breadcrumbList: [],
    }
  },
  watch: {
    $route() {
      this.updateList()
    },
  },
  computed: {
    ...trialSettingsMapGetters(),
    hasBreadcrumbItems() {
      return this.breadcrumbList?.length > 0
    },
    globalNavigation() {
      return [
        { name: 'ePID', link: 'home' },
        {
          name: this.trialNumber || this.userTrialNumber,
          link: this.trialNumber ? 'site' : 'sites',
        },
      ]
    },
  },
  methods: {
    getDisplayText(functionOrString) {
      return typeof functionOrString === 'function'
        ? functionOrString.call(this, this.$route.params)
        : functionOrString
    },
    updateList() {
      this.breadcrumbList = this.globalNavigation.concat(
        this.$route.meta.breadcrumb
      )
    },
  },
  mounted() {
    this.updateList()
  },
}
</script>
