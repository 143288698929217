import Vue from 'vue'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'

export default {
  requestArchive() {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .post(`/web/trial/archives`, { currentClientTime })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('requestArchive error', error, {
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  getArchives() {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .get(`/web/trial/archives`)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getArchives error', error, {
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  downloadArchive(archiveId: number) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .post(
          '/web/trial/archives/' + archiveId,
          { currentClientTime },
          { responseType: 'arraybuffer' }
        )
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('downloadArchive error', error, {
            archiveId,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
}
